<template>
	<div>
		<Navbar />
		<div class="privacyPolicy">
			<div class="privacyPolicyHeader">
				Privacy Policy
			</div>
			<div class="">
				<div class="privacyPolicyText">
					<div class="pa" v-for="(i, indx) in arr" :key="indx">
						<div class="heading__">
							{{ i.heading }}
						</div>
						<div class="content__">
							<ul>
								<li v-for="(j, indx2) in i.content" :key="indx2">
									{{ j }}
								</li>
							</ul>
						</div>
					</div>
				</div>

				<!-- <div>
					<img class="icon1" src="./icon.svg">
				</div> -->
			</div>
		</div>
		<!-- footer -->
		<FooterYic />
	</div>
</template>
<script>
import { BModal, VBModal, BAlert, BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";
import Navbar from "../navbar/Navbar.vue";
import FooterYic from '../homepage/footer.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import {
	BRow,
	BCol,
	BLink,
	BButton,
	BForm,
	BFormCheckbox,
	BFormGroup,
	BFormInput,
	BFormSelect,
	BInputGroup,
	BInputGroupAppend,
	BImg,
	BCardTitle,
} from "bootstrap-vue";
export default {
	components: {
		BButton,
		BModal,
		BAlert,
		Navbar,
		BRow,
		BImg,
		BCol,
		BLink,
		BForm,
		BCardText,
		BCardTitle,
		BFormCheckbox,
		BFormGroup,
		BFormInput,
		BFormSelect,
		BInputGroup,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		FooterYic,
	},
	directives: {
		Ripple,
	},
	data() {
		return {
			arr: [
				{
					heading: 'PRI10 Private Limited Privacy Policy',
					content: [
						'This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from www.yourindiancart.com (the “Site”).',
						'You fully agree to use the Platform, or third-party Platforms that link to the Platform and any associated Platform(s), in the manner in which it is intended for use. If you do not agree to this Policy, we advise you to stop using our services.',
						'We store, process, and collect your information where it is necessary and relevant to our dealings with you. The information that you share with us will be stored with us for as long as we are either required to by law or is relevant for the purpose for which it was collected. We will not share your information with any third party without your consent, unless we become legally compelled.'
					]
				},
				{
					heading: 'PERSONAL INFORMATION WE COLLECT',
					content: [
						'When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”'
					]
				},
				{
					heading: 'We collect Device Information using the following technologies',
					content: [
						'“Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org',
						'“Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.',
						'“Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.',
						'Additionally when you make a purchase or attempt to make a purchase through the Site, use our shipping services we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers), email address, and phone number. We refer to this information as “Order/Service Information.',
						'When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order / Service Information.',
					]
				},
				{
					heading: 'HOW DO WE USE YOUR PERSONAL INFORMATION?',
					content: [
						'We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations). Additionally, we use this Order Information to: Communicate with you, Improve customer service, processing your requests, Screen our orders for potential risk or fraud and when in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.',
						'We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).',

					]
				},
				{
					heading: 'SHARING YOUR PERSONAL INFORMATION',
					content: [
						'We may pass your Personal Information including your name and address on to a third party in order to deliver the services you have requested for.',
						'Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.',
					]
				},
				{
					heading: 'BEHAVIOURAL ADVERTISING',
					content: [
						'As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.',
						'Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at: http://optout.aboutads.info/',
					]
				},
				{
					heading: 'DATA RETENTION',
					content: [
						'When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information',
						'The Site is not intended for individuals under the age of 18.',
					]
				},
				{
					heading: 'CHANGES',
					content: [
						'We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.',
					]
				},
				{
					heading: 'CONTACT US',
					content: [
						'For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at support@yourindiancart.com',
					]
				},
				// {
				// 	heading: '',
				// 	content: [
				// 		'',
				// 	]
				// },

			]
		};
	},
};

</script>
<style scoped>
.privacyPolicy {
	padding-bottom: 3rem;
	background: white;
	padding: 0 8rem;
	color: #000;

}

.privacyPolicyHeader {
	font-weight: 600;
	font-size: 43px;
	color: #414141;
	margin: 2rem 0;
	margin-bottom: 0rem;
}

.privacyPolicyText {
	font-weight: 400;
	font-size: 18px;
	text-align: justify;
}

.icon1 {
	width: 229px;
	height: 336px;
	/* margin-left: 1096px; */
	margin-top: -30%;
	filter: drop-shadow(0px 4px 25px rgba(0, 167, 254, 0.15));
}

.privacyPolicyTextnImg {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

.heading__ {
	font-weight: 600;
	margin: 1rem 0;
}

.content__>ul {
    color: #000;
    font-size: 15px;
    font-weight: 400;
}

/*========================= Media Queries (Medium devices) =========================*/
@media screen and (max-width: 1000px) {
    .privacyPolicy {
        padding: 0 5rem;
    }
}

/*========================= Media Queries (Small devices) =========================*/
@media screen and (max-width: 600px) {
    .privacyPolicy {
        padding: 0 2rem;
    }
}

</style>